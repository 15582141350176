#nav-mobile > li:last-child {
  margin-left: 50px;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

.page-footer {
  padding-top: 0px;
}

span.badge {
  min-width: 6rem;
}

.fab {
  position: absolute;
  bottom: 12px;
  right: 12px;
}

.fab-left {
  position: absolute;
  bottom: 12px;
  right: 60px;
}

.indent {
  padding-left: 24px;
}

.more-indent {
  padding-left: 48px;
}

.scrollbar {
  height: 75vh;
  overflow: scroll;
  /* overflow-x: hidden; */
}

#curtime-per-drink {
  text-align: left;
  padding: 11.25px 11.25px;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

#order-spent-time {
  font-size: 24px;
  font-weight: 300;
  text-align: right;
}

.brew-times {
  font-size: 18px;
  font-weight: 300;
  text-align: right;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #444;
    color: #e4e4e4;
  }
  a {
    color: #e39777;
  }
  img {
    filter: grayscale(30%);
  }
}
